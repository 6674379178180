import React from "react"
import { injectIntl, Link } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import Footer from '../components/footer';
import SEO from '../components/seo';

const Thanks = ({ intl }) => (
    <Layout>
        <SEO title="Thank you" />

        <div className="main dark">
            {/* MARKETO */}
            <div className="block standard form">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <h2>{intl.formatMessage({ id: "thankyou_heading" })}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7">
                            <p>{intl.formatMessage({ id: "thankyou_copy" })}</p>
                            <Link to="/" title="" className="link-text"><span>{intl.formatMessage({ id: "thankyou_cta" })}</span></Link>
                        </div>
                    </div>

                </div>
            </div>

            {/* FOOTER */}
            <Footer />
        </div>
    </Layout>
)

export default injectIntl(Thanks)
